<template>
    <div class="content-wrapper">
        <div class="content">
            <h1>Страница не найдена</h1>
            <h2><router-link to="/projects">К проектам</router-link></h2>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NotFound',
    components: {},
    data: () => ({}),
    computed: {},
    methods: {},
}
</script>
<style scoped></style>
